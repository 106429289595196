/* stylelint-disable */
/*
* Этот файл сгенерирован автоматически. Не надо править его руками.
*/
body[scheme="vkcom_light"],
[scheme="vkcom_light"] {
  --accent: #5181b8;
  --accent_alternate: #0077ff;
  --action_sheet_action_foreground: #5181b8;
  --action_sheet_separator: rgba(0, 0, 0, 0.12);
  --activity_indicator_tint: #aeb7c2;
  --attach_picker_tab_active_background: #5181b8;
  --attach_picker_tab_active_icon: #ffffff;
  --attach_picker_tab_active_text: #5181b8;
  --attach_picker_tab_inactive_background: #f5f5f5;
  --attach_picker_tab_inactive_icon: #818c99;
  --attach_picker_tab_inactive_text: #818c99;
  --background_content: #ffffff;
  --background_highlighted: rgba(174, 183, 194, 0.16);
  --background_hover: rgba(174, 183, 194, 0.12);
  --background_keyboard: #dce1e6;
  --background_light: #fafbfc;
  --background_page: #edeef0;
  --background_suggestions: #ffffff;
  --background_text_highlighted: rgba(81, 129, 184, 0.2);
  --button_bot_shadow: #cccccc;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #e5ebf1;
  --button_muted_foreground: #55677d;
  --button_outline_border: #2a5885;
  --button_outline_foreground: #2a5885;
  --button_primary_background: #5181b8;
  --button_primary_foreground: #ffffff;
  --button_secondary_background: #e5ebf1;
  --button_secondary_background_highlighted: #dae2ea;
  --button_secondary_destructive_background: rgba(230, 70, 70, 0.16);
  --button_secondary_destructive_background_highlighted: rgba(
    230,
    70,
    70,
    0.12
  );
  --button_secondary_destructive_foreground: #e64646;
  --button_secondary_destructive_foreground_highlighted: rgba(
    230,
    70,
    70,
    0.12
  );
  --button_secondary_foreground: #55677d;
  --button_secondary_foreground_highlighted: #55677d;
  --button_tertiary_background: rgba(0, 0, 0, 0);
  --button_tertiary_foreground: #2a5885;
  --cell_button_foreground: #2a5885;
  --content_negative_background: #ff5c5c;
  --content_placeholder_icon: #99a2ad;
  --content_placeholder_text: #818c99;
  --content_positive_background: #def0d3;
  --content_tint_background: #f0f2f5;
  --content_tint_foreground: #828282;
  --content_warning_background: #faefd2;
  --control_background: #edeef0;
  --control_foreground: #5181b8;
  --control_tint: #ffffff;
  --control_tint_muted: #828282;
  --counter_primary_background: #5181b8;
  --counter_primary_text: #ffffff;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #99a2ad;
  --counter_secondary_text: #ffffff;
  --destructive: #e64646;
  --dynamic_blue: #5181b8;
  --dynamic_gray: #aeb7c2;
  --dynamic_green: #1b7a1b;
  --dynamic_orange: #ffa000;
  --dynamic_purple: #735ce6;
  --dynamic_raspberry_pink: #e03fab;
  --dynamic_red: #ff3347;
  --dynamic_violet: #792ec0;
  --feed_recommended_friend_promo_background: #5181b8;
  --field_background: #f7f8fa;
  --field_border: #dce1e6;
  --field_error_background: #faebeb;
  --field_error_border: #e64646;
  --field_text_placeholder: #818c99;
  --field_valid_border: #4bb34b;
  --float_button_background: #f7f8fa;
  --float_button_background_highlighted: #edeef0;
  --float_button_border: rgba(0, 0, 0, 0.08);
  --float_button_foreground: #99a2ad;
  --header_alternate_background: #ffffff;
  --header_alternate_tab_active_indicator: #5181b8;
  --header_alternate_tab_active_text: #000000;
  --header_alternate_tab_inactive_text: #99a2ad;
  --header_background: #ffffff;
  --header_background_before_blur: #ffffff;
  --header_background_before_blur_alternate: #ffffff;
  --header_search_field_background: #edeef0;
  --header_search_field_tint: #818c99;
  --header_tab_active_background: rgba(0, 0, 0, 0);
  --header_tab_active_indicator: #5181b8;
  --header_tab_active_text: #000000;
  --header_tab_inactive_text: #99a2ad;
  --header_text: #000000;
  --header_text_alternate: #000000;
  --header_text_secondary: #818c99;
  --header_tint: #aeb7c2;
  --header_tint_alternate: #0077ff;
  --icon_alpha_placeholder: #ffffff;
  --icon_medium: #6f7985;
  --icon_medium_alpha: rgba(0, 0, 0, 0.48);
  --icon_name: #8fadc8;
  --icon_outline_medium: #818c99;
  --icon_outline_secondary: #99a2ad;
  --icon_secondary: #99a2ad;
  --icon_secondary_alpha: rgba(0, 0, 0, 0.36);
  --icon_tertiary: #aeb7c2;
  --icon_tertiary_alpha: rgba(0, 0, 0, 0.24);
  --im_attach_tint: #5181b8;
  --im_bubble_border_alternate: #dce1e6;
  --im_bubble_border_alternate_highlighted: #cccccc;
  --im_bubble_button_background: #f7f8fa;
  --im_bubble_button_background_highlighted: #cccccc;
  --im_bubble_button_foreground: #000000;
  --im_bubble_button_outgoing_background: rgba(255, 255, 255, 0.72);
  --im_bubble_gift_background: #f4e7c3;
  --im_bubble_gift_background_highlighted: #e3d3ac;
  --im_bubble_gift_text: #857250;
  --im_bubble_gift_text_secondary: #ab9871;
  --im_bubble_incoming: #edeef0;
  --im_bubble_incoming_alternate: #ffffff;
  --im_bubble_incoming_alternate_highlighted: #f7f8fa;
  --im_bubble_incoming_expiring: #e6e9ff;
  --im_bubble_incoming_expiring_highlighted: #ccd3ff;
  --im_bubble_incoming_highlighted: #d3d9de;
  --im_bubble_outgoing: #cce4ff;
  --im_bubble_outgoing_alternate: #ffffff;
  --im_bubble_outgoing_alternate_highlighted: #f0f2f5;
  --im_bubble_outgoing_expiring: #ccd3ff;
  --im_bubble_outgoing_expiring_highlighted: #adb8ff;
  --im_bubble_outgoing_highlighted: #add3ff;
  --im_bubble_wallpaper_button_background: #f0f2f5;
  --im_bubble_wallpaper_button_background_highlighted: #e7e8ec;
  --im_bubble_wallpaper_button_foreground: #000000;
  --im_bubble_wallpaper_incoming: #ffffff;
  --im_bubble_wallpaper_incoming_highlighted: #edeef0;
  --im_bubble_wallpaper_outgoing: #cce4ff;
  --im_bubble_wallpaper_outgoing_highlighted: #add3ff;
  --im_forward_line_tint: rgba(0, 20, 51, 0.12);
  --im_reply_separator: #2a5885;
  --im_service_message_text: #818c99;
  --im_text_name: #2a5885;
  --im_toolbar_separator: #001c3d;
  --im_toolbar_voice_msg_background: #99a2ad;
  --image_border: rgba(0, 0, 0, 0.08);
  --input_background: #ffffff;
  --input_border: #d3d9de;
  --landing_background: #ffffff;
  --landing_field_background: #f7f8fa;
  --landing_field_border: rgba(0, 0, 0, 0);
  --landing_field_placeholder: #818c99;
  --landing_login_button_background: #5181b8;
  --landing_login_button_foreground: #ffffff;
  --landing_primary_button_background: #5181b8;
  --landing_primary_button_foreground: #ffffff;
  --landing_secondary_button_background: rgba(0, 57, 115, 0.1);
  --landing_secondary_button_foreground: #5181b8;
  --landing_snippet_border: rgba(0, 0, 0, 0.12);
  --landing_tertiary_button_background: rgba(0, 0, 0, 0);
  --landing_tertiary_button_foreground: #5181b8;
  --landing_text_primary: #000000;
  --landing_text_secondary: #818c99;
  --landing_text_title: #5181b8;
  --like_text_tint: #ff3347;
  --link_alternate: #2a5885;
  --loader_background: #edeef0;
  --loader_tint: #c5d0db;
  --loader_track_fill: #d3d9de;
  --loader_track_value_fill: #5181b8;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #333333;
  --modal_card_background: #ffffff;
  --modal_card_border: rgba(0, 0, 0, 0);
  --modal_card_header_close: #000000;
  --music_playback_icon: #000000;
  --overlay_status_background: #edeef0;
  --overlay_status_foreground: #6f7985;
  --overlay_status_icon: #818c99;
  --panel_tab_active_background: #edeef0;
  --panel_tab_active_text: #4e5966;
  --panel_tab_inactive_text: #6f7985;
  --placeholder_icon_background: rgba(0, 28, 61, 0.08);
  --placeholder_icon_background_opaque: #f0f2f5;
  --placeholder_icon_foreground_primary: #99a2ad;
  --placeholder_icon_foreground_secondary: #aeb7c2;
  --placeholder_icon_tint: #1d3a5c;
  --poll_option_background: #5181b8;
  --primary_overlay_badge: rgba(0, 0, 0, 0.56);
  --search_bar_background: rgba(0, 0, 0, 0);
  --search_bar_field_background: #edeef0;
  --search_bar_field_tint: #818c99;
  --search_bar_segmented_control_tint: #818c99;
  --secondary_overlay_badge: rgba(0, 0, 0, 0.24);
  --segmented_control_bar_background: rgba(0, 0, 0, 0);
  --segmented_control_tint: #99a2ad;
  --selection_off_icon: #c5d0db;
  --separator_alpha: rgba(0, 0, 0, 0.12);
  --separator_alternate: #e7e8ec;
  --separator_common: #dce1e6;
  --skeleton_foreground_from: #f0f2f5;
  --skeleton_foreground_to: #e7e8ec;
  --skeleton_shimmer_from: #ffffff;
  --skeleton_shimmer_to: rgba(255, 255, 255, 0);
  --snippet_background: #ffffff;
  --snippet_border: rgba(0, 0, 0, 0.12);
  --snippet_icon_tertiary: #aeb7c2;
  --splashscreen_icon: #dce1e6;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0.24);
  --stories_create_button_background_from: #f0f2f5;
  --stories_create_button_background_to: #dce1e6;
  --stories_create_button_foreground: #6f7985;
  --stories_create_button_icon_background: #ffffff;
  --stories_skeleton_loader_background: #cccccc;
  --switch_ios_off_border: #dce1e6;
  --tabbar_active_icon: #5181b8;
  --tabbar_background: #f7f8fa;
  --tabbar_inactive_icon: #99a2ad;
  --tabbar_tablet_active_icon: #5181b8;
  --tabbar_tablet_background: #f7f8fa;
  --tabbar_tablet_inactive_icon: #99a2ad;
  --tabbar_tablet_text_primary: #000000;
  --tabbar_tablet_text_secondary: #818c99;
  --text_action_counter: #818c99;
  --text_link: #2a5885;
  --text_link_highlighted_background: #000000;
  --text_muted: #333333;
  --text_name: #2a5885;
  --text_placeholder: #818c99;
  --text_primary: #000000;
  --text_secondary: #818c99;
  --text_subhead: #626d7a;
  --text_tertiary: #99a2ad;
  --toolbar_attach_background_from: #dce1e6;
  --toolbar_attach_background_to: #aeb7c2;
  --vk_connect_button_primary_background: #222222;
  --vk_connect_button_primary_foreground: #ffffff;
  --vk_connect_button_secondary_background: #f5f5f5;
  --vk_connect_button_secondary_foreground: #222222;
  --writebar_icon: #818c99;
}
/* stylelint-enable */
